import ReactMarkdown from 'react-markdown'

function Features(params) {
    return (
        <section className="features section">
            <div className="container-md">
                <div className="features-inner section-inner">
                    <div className="features-header text-center">
                        <div className="container-sm">
                            <h2 className="section-title mt-0">
                                {params.data.flatData.titulo}
                            </h2>
                            <p className="section-paragraph">
                                {params.data.flatData.descripcion}
                            </p>
                        </div>
                    </div>
                    <div className="features-wrap">

                        {
                            params.data.flatData.servicios.map((item, index) => {
                                var url = item.url ?? "";
                                return (
                                    <a
                                        key={index}
                                        href={url}
                                        target={((url.includes("http")) ? "_blank" : "_self")}
                                        rel="noreferrer"
                                        className={
                                            "feature text-center is-revealing cursor-pointer md:hover:ring-4 md:hover:ring-blue-400 hover:no-underline"
                                        }
                                    >
                                        <div className="feature-inner hover:bg-blue-50">
                                            <div className="feature-icon">
                                                <img className='feature-icon' style={{ height: '88px' }} src={item.icono[0].url} alt={item.titulo}>
                                                </img>
                                            </div>
                                            <h4 className="feature-title h3-mobile mb-8 text-gray-700">
                                                {item.titulo}
                                            </h4>
                                            <ReactMarkdown className='text-gray-700'>
                                                {item.descripcion}
                                            </ReactMarkdown>
                                        </div>
                                    </a>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;