import { React, Component } from "react";
import Slider from "../components/slider";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <Slider
                    options={{
                        autoPlay: 3000,
                        pauseAutoPlayOnHover: true,
                        wrapAround: true,
                        fullscreen: true,
                        adaptiveHeight: false,
                        prevNextButtons: false,
                        imagesLoaded: true,
                        pageDots: true,
                        draggable: true,
                        dragThreshold: 40,
                    }}
                    elements={(this.props.data.elementos ?? []).map((dataItem, index) => {
                        var item = dataItem;
                        var title = item.titulo;
                        var imageUrl = item.imagen[0].url;
                        var url = item.url ?? "";
                        return <a
                            href={url}
                            rel="noreferrer"
                            target={((url.includes("http")) ? "_blank" : "_self")}
                            style={{ width: '100%' }}
                            key={index}
                        >
                            <img className="rounded-md" src={imageUrl} alt={title} style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }} />
                        </a>
                    })}
                >
                </Slider>
            </>
        );
    }
}

export default Banner;