import { React, Component } from "react";
import ReactMarkdown from 'react-markdown'

class TextMD extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <ReactMarkdown components={{
                    h1: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h1 className="text-xl md:text-4xl font-bold my-2" {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h2 className="text-lg md:text-2xl font-bold my-3" {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h3 className="text-base md:text-lg font-bold my-4" {...props} />
                    ),
                    h4: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h4 className="text-sm md:text-base font-bold my-5" {...props} />
                    ),
                    h5: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h5 className="text-xs md:text-sm font-bold my-6" {...props} />
                    ),
                    h6: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <h6 className="text-xs md:text-xs font-bold my-8 text-gray-500" {...props} />
                    ),
                    p: ({ node, ...props }) => (
                        <p {...props} className="my-4 text-[15px] md:text-[17px] md:font-[500] text-gray-700" />
                    ),
                    li: ({ node, ...props }) => (
                        <li className="pl-2 text-[15px] md:text-[17px] md:font-[500] text-gray-700"
                            {...props}
                        />
                    ),
                    ul: ({ node, ...props }) => (
                        <ul className="list-disc my-1 pl-10"
                            {...props}
                        />
                    ),
                    ol: ({ node, ...props }) => (
                        <ol className="list-decimal my-4 pl-10"
                            {...props}
                        />
                    ),
                    blockquote: ({ node, ...props }) => (
                        <blockquote {...props} className="p-4 py-1 my-4 mx-4 border-l-4 border-gray-300 bg-gray-50" />
                    ),
                    a: ({ node, ...props }) => (
                        // eslint-disable-next-line
                        <a {...props} className="text-blue-700 underline" />
                    ),
                }}>
                    {
                        this.props.data.contenido
                    }
                </ReactMarkdown>
            </div>
        );
    }
}

export default TextMD;