import { React, Component } from "react";

import Banner from './banner'
import TextMD from "./textmd";

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    defineElement(element) {
        switch (element.tipo) {
            case 'banner':
                return <Banner data={element} />;
            case 'text':
                return <TextMD data={element} />;
            default:
                return null;
        }
    }

    defineMargins(element) {
        switch (element.tipo) {
            case 'banner':
                return 'mb-6 md:mb-12';
            case 'text':
                return 'mb-2 md:mb-4';
            default:
                return null;
        }
    }

    render() {
        return (
            <>
                <div className={this.defineMargins(this.props.data)}>
                    {
                        (this.props.data.titulo && this.props.data.mostrarTitulo)
                            ? <h6 className="text-gray-800 text-[16px] sm:text-lg md:text-xl lg:text-2xl font-semibold text-left mb-5 ease-linear transition-all duration-150">
                                {this.props.data.titulo}
                            </h6>
                            : null
                    }
                    {
                        this.defineElement(this.props.data)
                    }
                </div>
            </>
        );
    }
}

export default Section;