import { React, Component } from "react";
import Hero from './sections/hero.js';
import Features from './sections/features.js';
import Testimonials from './sections/testimonials.js';

import Prevent from './sections/prevent.js';
import Clients from './sections/clients.js';
import '../CmsApi.js'

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingText: "Iniciando...",
            data: null,
        };
    }

    componentDidMount() {
        global.cmsApi.getHomeCms((data) => {
            try {
                this.setState({
                    data: data
                })
                this.setLoading(false)
            } catch (error) {

            }
        });
    }

    setLoading(isLoading, loadingText) {
        this.setState({ isLoading: isLoading, loadingText: loadingText });
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading
                        ? <div>
                            Cargando...
                        </div>
                        : null
                }
                {
                    this.state.data
                        ? <main>
                            <Hero data={this.state.data.querySeccionInicioContents[0]} />
                            <Features data={this.state.data.querySeccionServiciosContents[0]} />
                            <Testimonials data={this.state.data.querySeccionTestimoniosContents[0]} />
                            <Clients data={this.state.data.querySeccionClientesContents[0]} />
                            <Prevent data={this.state.data.querySeccionNosotrosContents[0]} />
                        </main>
                        : null
                }
            </>
        );
    }
}

export default Main;