import ReactMarkdown from 'react-markdown'

function Hero(params) {
    return (
        <section className="hero">
            <div className="container">
                <div className="hero-inner">
                    <div className="hero-copy">
                        <h1 className="hero-title mt-0">
                            {params.data.flatData.titulo}
                        </h1>
                        <div className="hero-paragraph">
                            <ReactMarkdown>
                                {params.data.flatData.contenido.text}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <div className="hero-illustration">
                        <div className="hero-shape hero-shape-1">
                            <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible' }}>
                                <circle className="anime-element fadeup-animation" cx={20} cy={20} r={20} fill="#FFD8CD" fillRule="evenodd" />
                            </svg>
                        </div>
                        <div className="hero-shape hero-shape-2">
                            <svg width={88} height={88} xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible' }}>
                                <circle className="anime-element fadeup-animation" cx={44} cy={44} r={44} fill="#FFD2DA" fillRule="evenodd" />
                            </svg>
                        </div>
                        <div className="hero-main-shape">
                            <svg width={940} height={647} viewBox="0 0 940 647" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ overflow: 'visible' }}>
                                <defs>
                                    <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="hero-illustration-a">
                                        <stop stopColor="#01c2e7" offset="0%" />
                                        <stop stopColor="#00a3c2" offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="89.764%" y1="16.809%" x2="11.987%" y2="82.178%" id="hero-illustration-b">
                                        <stop stopColor="#FC8464" offset="0%" />
                                        <stop stopColor="#FB5C32" offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="100%" y1="23.096%" x2="4.439%" y2="96.586%" id="hero-illustration-c">
                                        <stop stopColor="#1ADAB7" offset="0%" />
                                        <stop stopColor="#55EBD0" offset="100%" />
                                    </linearGradient>
                                    <filter x="-13%" y="-150%" width="126.1%" height="400%" filterUnits="objectBoundingBox" id="hero-illustration-d">
                                        <feGaussianBlur stdDeviation={32} in="SourceGraphic" />
                                    </filter>
                                    <linearGradient x1="0%" y1="100%" x2="46.694%" y2="42.915%" id="hero-illustration-f">
                                        <stop stopColor="#EEF1FA" offset="0%" />
                                        <stop stopColor="#FFF" offset="100%" />
                                    </linearGradient>
                                    <rect id="hero-illustration-e" width={800} height={450} rx={4} />
                                    <linearGradient x1="100%" y1="-12.816%" x2="0%" y2="-12.816%" id="hero-illustration-g">
                                        <stop stopColor="#D2DAF0" stopOpacity={0} offset="0%" />
                                        <stop stopColor="#D2DAF0" offset="50.045%" />
                                        <stop stopColor="#D2DAF0" stopOpacity={0} offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="116.514%" y1="-21.201%" x2="0%" y2="100%" id="hero-illustration-h">
                                        <stop stopColor="#55EBD0" offset="0%" />
                                        <stop stopColor="#4950F6" offset="100%" />
                                    </linearGradient>
                                    <path id="hero-illustration-j" d="M0 0h308v288H0z" />
                                    <filter x="-15.6%" y="-12.5%" width="139%" height="141.7%" filterUnits="objectBoundingBox" id="hero-illustration-i">
                                        <feOffset dx={12} dy={24} in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur stdDeviation={16} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                        <feColorMatrix values="0 0 0 0 0.0666666667 0 0 0 0 0.062745098 0 0 0 0 0.243137255 0 0 0 0.08 0" in="shadowBlurOuter1" />
                                    </filter>
                                    <circle id="hero-illustration-k" cx={16} cy={16} r={16} />
                                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-illustration-m">
                                        <stop stopColor="#C6FDF3" offset="0%" />
                                        <stop stopColor="#C6FDF3" stopOpacity={0} offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="2.875%" y1="89.028%" x2="99.412%" y2="6.885%" id="hero-illustration-n">
                                        <stop stopColor="#83F0DD" offset="0%" />
                                        <stop stopColor="#1ADAB7" offset="50.924%" />
                                        <stop stopColor="#55EBD0" offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="50%" y1="1.569%" x2="50%" y2="97.315%" id="hero-illustration-o">
                                        <stop stopColor="#FF97AA" offset="0%" />
                                        <stop stopColor="#FF6381" offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-illustration-p">
                                        <stop stopColor="#FCAC96" offset="0%" />
                                        <stop stopColor="#FC8464" offset="100%" />
                                    </linearGradient>
                                    <circle id="hero-illustration-r" cx={28} cy={28} r={28} />
                                    <filter x="-85.7%" y="-64.3%" width="314.3%" height="314.3%" filterUnits="objectBoundingBox" id="hero-illustration-q">
                                        <feOffset dx={12} dy={24} in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur stdDeviation={16} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                        <feColorMatrix values="0 0 0 0 0.0666666667 0 0 0 0 0.062745098 0 0 0 0 0.243137255 0 0 0 0.08 0" in="shadowBlurOuter1" />
                                    </filter>
                                    <circle id="hero-illustration-t" cx={36} cy={36} r={36} />
                                    <filter x="-66.7%" y="-50%" width="266.7%" height="266.7%" filterUnits="objectBoundingBox" id="hero-illustration-s">
                                        <feOffset dx={12} dy={24} in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur stdDeviation={16} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                        <feColorMatrix values="0 0 0 0 0.0666666667 0 0 0 0 0.062745098 0 0 0 0 0.243137255 0 0 0 0.08 0" in="shadowBlurOuter1" />
                                    </filter>
                                    <circle id="hero-illustration-v" cx={28} cy={28} r={28} />
                                    <filter x="-85.7%" y="-64.3%" width="314.3%" height="314.3%" filterUnits="objectBoundingBox" id="hero-illustration-u">
                                        <feOffset dx={12} dy={24} in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur stdDeviation={16} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                        <feColorMatrix values="0 0 0 0 0.0666666667 0 0 0 0 0.062745098 0 0 0 0 0.243137255 0 0 0 0.08 0" in="shadowBlurOuter1" />
                                    </filter>
                                    <circle id="hero-illustration-x" cx={24} cy={24} r={24} />
                                    <filter x="-100%" y="-75%" width="350%" height="350%" filterUnits="objectBoundingBox" id="hero-illustration-w">
                                        <feOffset dx={12} dy={24} in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur stdDeviation={16} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                                        <feColorMatrix values="0 0 0 0 0.0666666667 0 0 0 0 0.062745098 0 0 0 0 0.243137255 0 0 0 0.08 0" in="shadowBlurOuter1" />
                                    </filter>
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                    <path className="anime-element stroke-animation" d="M600,300 C600,465.685425 465.685425,600 300,600 C134.314575,600 1.3749042e-14,465.685425 3.60373576e-15,300 C-6.54157051e-15,134.314575 134.314575,-1.29473326e-14 300,-2.30926389e-14 C465.685425,-3.32379452e-14 600,134.314575 600,300 Z" fill="url(#hero-illustration-a)" stroke="#01c2e7" />
                                    <g transform="translate(435 518)">
                                        <circle className="anime-element fadeup-animation" fill="url(#hero-illustration-b)" cx={106} cy={32} r={32} />
                                        <circle className="anime-element fadeup-animation" fill="url(#hero-illustration-c)" cx={12} cy={117} r={12} />
                                    </g>
                                    <g className="anime-element fadeleft-animation">
                                        <g transform="translate(103 75)">
                                            <path fillOpacity=".24" fill="#11103E" filter="url(#hero-illustration-d)" d="M32 410h736v64H32z" />
                                            <use fill="url(#hero-illustration-f)" xlinkHref="#hero-illustration-e" />
                                        </g>
                                        <g transform="translate(123 87)">
                                            <circle fill="#D2DAF0" cx={4} cy={4} r={4} />
                                            <circle fill="#D2DAF0" cx={20} cy={4} r={4} />
                                            <circle fill="#D2DAF0" cx={36} cy={4} r={4} />
                                            <path fillOpacity=".56" fill="#FFF" d="M736 2h24v4h-24z" />
                                            <path fill="url(#hero-illustration-g)" d="M27 20h706v2H27z" />
                                        </g>
                                        <g transform="translate(396 157)">
                                            <path fill="#FFF" d="M0 0h308v144H0z" />
                                            <path fill="#EEF1FA" d="M28 119h252v1H28zM28 94h252v1H28zM28 69h252v1H28zM28 44h252v1H28z" />
                                            <path stroke="url(#hero-illustration-h)" strokeWidth={3} d="M26 119l49.19-38.316 14.615 19.035 16.36-19.035 31.306 4.272 33.079-23.54 34.86 38.303 15.625-50.412 16.356 12.109 16.472-12.11 15.075 21.443 16.976-21.442" />
                                            <rect fill="#D2DAF0" x={28} y={20} width={24} height={4} rx={2} />
                                        </g>
                                        <g>
                                            <path fill="#FFF" d="M396 333h308v144H396z" />
                                            <path fill="#EEF1FA" d="M424 452h252v1H424zM424 427h252v1H424zM424 402h252v1H424zM424 377h252v1H424z" />
                                            <path fill="url(#hero-illustration-o)" d="M28 24h12v96H28z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-p)" d="M52 67h12v53H52z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-o)" d="M76 24h12v96H76z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-p)" d="M100 82h12v38h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-o)" d="M124 45h12v75h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-p)" d="M148 67h12v53h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-o)" d="M172 82h12v38h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-p)" d="M196 45h12v75h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-o)" d="M220 67h12v53h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-p)" d="M244 82h12v38h-12z" transform="translate(396 333)" />
                                            <path fill="url(#hero-illustration-o)" d="M268 45h12v75h-12z" transform="translate(396 333)" />
                                        </g>
                                    </g>
                                    <g className="anime-element fadeleft-animation">
                                        <image
                                            transform="translate(56 157)"
                                            height="300"
                                            href={params.data.flatData.imagenDerecha[0].url}
                                        />
                                    </g>
                                    <g>
                                        <g className="anime-element fadeleft-animation">
                                            <g transform="translate(164 483)">
                                                <use fill="#000" filter="url(#hero-illustration-q)" xlinkHref="#hero-illustration-r" />
                                                <use fill="#FFF" xlinkHref="#hero-illustration-r" />
                                            </g>
                                            {/* <path d="M200 506c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4h-.8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" fill="#1DA1F2" fillRule="nonzero" /> */}
                                            <text fontFamily="AppleColorEmoji, Apple Color Emoji" fontSize={25} fill="#11103E" transform="translate(164 483)">
                                                <tspan x={12} y={38}>🧼</tspan>
                                            </text>
                                        </g>
                                        <g className="anime-element fadeleft-animation">
                                            <g transform="translate(300 420)">
                                                <use fill="#000" filter="url(#hero-illustration-s)" xlinkHref="#hero-illustration-t" />
                                                <use fill="#FFF" xlinkHref="#hero-illustration-t" />
                                            </g>
                                            {/* <g fillRule="nonzero">
                                                <path d="M290.56 415.485a2.137 2.137 0 1 0-4.066 1.32l5.526 17.004a2.137 2.137 0 0 0 2.6 1.325c1.135-.327 1.826-1.532 1.464-2.646l-5.525-17.003" fill="#DFA22F" />
                                                <path d="M281.996 418.267a2.137 2.137 0 0 0-4.065 1.321l5.526 17.003a2.137 2.137 0 0 0 2.6 1.325c1.134-.326 1.826-1.531 1.464-2.645l-5.525-17.004" fill="#3CB187" />
                                                <path d="M297.515 429.567a2.137 2.137 0 1 0-1.32-4.065l-17.004 5.526a2.137 2.137 0 0 0-1.325 2.6c.327 1.134 1.532 1.825 2.646 1.464l17.003-5.525" fill="#CE1E5B" />
                                                <path fill="#392538" d="M282.735 434.37l4.064-1.321-1.32-4.065-4.065 1.321 1.321 4.064" />
                                                <path d="M291.298 431.587l4.064-1.32-1.32-4.066-4.065 1.321 1.321 4.065" fill="#BB242A" />
                                                <path d="M294.733 421.004a2.137 2.137 0 0 0-1.321-4.066l-17.003 5.527a2.136 2.136 0 0 0-1.325 2.6c.326 1.134 1.531 1.825 2.645 1.463l17.004-5.524" fill="#72C5CD" />
                                                <path d="M279.952 425.806l4.065-1.32c-.5-1.537-.964-2.965-1.32-4.065l-4.066 1.322 1.321 4.063" fill="#248C73" />
                                                <path d="M288.515 423.024l4.065-1.32-1.321-4.066-4.065 1.321 1.321 4.065" fill="#62803A" />
                                            </g> */}
                                            <text fontFamily="AppleColorEmoji, Apple Color Emoji" fontSize={35} fill="#11103E" transform="translate(300 420)">
                                                <tspan x={22} y={45}>🌡️</tspan>
                                            </text>
                                        </g>
                                        <g className="anime-element fadeleft-animation">
                                            <g transform="translate(28 221)">
                                                <use fill="#000" filter="url(#hero-illustration-u)" xlinkHref="#hero-illustration-v" />
                                                <use fill="#FFF" xlinkHref="#hero-illustration-v" />
                                            </g>
                                            <text fontFamily="AppleColorEmoji, Apple Color Emoji" fontSize={26} fill="#11103E" transform="translate(28 221)">
                                                <tspan x={10} y={36}>🐀</tspan>
                                            </text>
                                        </g>
                                        <g className="anime-element fadeleft-animation">
                                            <g transform="translate(390 150)">
                                                <use fill="#000" filter="url(#hero-illustration-w)" xlinkHref="#hero-illustration-x" />
                                                <use fill="#FFF" xlinkHref="#hero-illustration-x" />
                                            </g>
                                            <text fontFamily="AppleColorEmoji, Apple Color Emoji" fontSize={24} fill="#11103E" transform="translate(390 150)">
                                                <tspan x={8} y={32}>🚭</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;