import ReactMarkdown from 'react-markdown'

function Prevent(params) {
    return (
        <section className="section">
            <div className="container">
                <div className="section-inner">
                    <div className="text-center">
                        <div className="container-sm">
                            <h2 className="mt-0 mb-0">
                                {params.data.flatData.titulo}
                            </h2>
                            <p className="section-paragraph">
                                {params.data.flatData.descripcion}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="container">
                            <ReactMarkdown>
                                {params.data.flatData.contenido.text}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Prevent;