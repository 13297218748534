import { queryCMS } from './Api.js'

global.cmsApi = {

    getCmsItem: function (code) {
        var items = global.cmsDefaultData.filter(item => item.code === code);
        if (items.length > 0) {
            return items[0];
        } else {
            return null;
        }
    },

    getCmsData: function (code, completion) {
        var cmsItem = this.getCmsItem(code)
        if (cmsItem == null) return;
        var local = localStorage.getItem(code);
        if (local) {
            var localData = JSON.parse(local);
            completion(localData);
            console.log("Local")
        } else {
            completion(cmsItem.data.data);
            console.log("Project")
        }
        queryCMS(cmsItem.query).then((data) => {
            try {
                completion(data);
                console.log("Cloud")
                localStorage.setItem(code, JSON.stringify(data));
            } catch (error) {

            }
        }, (error) => {

        });
    },

    getHomeCms: function (completion) {
        var code = "CmsHome"
        this.getCmsData(code, completion);
    },

    getPageCms: function (pageId, completion) {
        var query = `
        queryPaginaContents(filter: "data/CodigoPagina/iv eq '${pageId}'") {
            flatData {
                secciones {
                  ... on TextoComponent {
                    tipo
                    contenido
                  }
                  ... on BannersComponent {
                    tipo
                    titulo
                    mostrarTitulo
                    elementos {
                      titulo
                      imagen {
                        url
                      }
                      url
                    }
                  }
                }
              }
            }
        `
        queryCMS(query).then((data) => {
            try {
                completion(data);
                console.log("Cloud")
            } catch (error) {
                //loadDefaultImages();
            }
        }, (error) => {
            //loadDefaultImages();
        });
    },

}

global.cmsDefaultData = [
    {
        code: "CmsHome",
        query:
            `
            querySeccionInicioContents {
                flatData {
                    titulo
                    contenido {
                        text
                    }
                    imagenDerecha {
                        url
                    }
                }
            }
            querySeccionServiciosContents {
                flatData {
                    titulo
                    descripcion
                    servicios {
                        titulo
                        url
                        descripcion
                        icono {
                            url
                        }
                    }
                }
            }
            querySeccionTestimoniosContents {
                flatData {
                    titulo
                    descripcion
                    testimonios {
                        cliente
                        descripcion
                        imagen {
                            url
                        }
                    }
                }
            }
            querySeccionClientesContents {
                flatData {
                    titulo
                    descripcion
                }
            }
            querySeccionNosotrosContents {
                flatData {
                    titulo
                    descripcion
                    contenido {
                        text
                    }
                }
            }
        `,
        data:
        {
            "data": {
                "querySeccionInicioContents": [
                    {
                        "flatData": {
                            "titulo": "Riesgo Bajo Control",
                            "contenido": {
                                "text": "✔️ LIMPIEZA Y DESINFECCION  \n✔️ CONTROL DE PLAGAS  \n✔️ MANEJO DE RESIDUOS SOLIDOS Y LIQUIDOS  \n✔️ CONTROL SUMINISTRO DE AGUA  \n✔️ CAPACITACION MANIPULACION DE ALIMENTOS  \n✔️ SG - SST   \n✔️ Productos especializados (L-D)"
                            },
                            "imagenDerecha": [
                                {
                                    "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/634411b1-24df-4b19-8705-c06f47f4edd7/"
                                }
                            ]
                        }
                    }
                ],
                "querySeccionServiciosContents": [
                    {
                        "flatData": {
                            "titulo": "Nuestro Servicio",
                            "descripcion": "",
                            "servicios": [
                                {
                                    "titulo": "Saneamiento Básico",
                                    "descripcion": "Elaboramos su plan de saneamiento básico, según resolución 2674 de 2013 del Ministerio de Salud.\n",
                                    "icono": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/4d55db7c-e027-480d-b70a-0b44ad0a31f4/"
                                        }
                                    ]
                                },
                                {
                                    "titulo": "Química de Aseo",
                                    "descripcion": "Suministramos jabón líquido especializado para limpieza en la industria de alimentos, fichas técnicas y hojas de seguridad.",
                                    "icono": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/5644cb66-4abc-4e58-9abf-13b305316467/"
                                        }
                                    ]
                                },
                                {
                                    "titulo": "Manipulación de Alimentos",
                                    "descripcion": "Programa especializado para manipuladores de alimentos y el respectivo cronograma de capacitaciones.",
                                    "icono": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/00728772-e356-412c-b0dd-aecd5a8424ef/"
                                        }
                                    ]
                                },
                                {
                                    "titulo": "SG - SST",
                                    "descripcion": "✔️ Definir y diseñar las politicas  \n✔️ Planificacion del SG-SST  \n✔️ Implementacion  \n✔️ Verificacion y Auditoria",
                                    "icono": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/9b7319e0-3ace-4559-912e-dfa9920f4fa4/"
                                        }
                                    ]
                                },
                                {
                                    "titulo": "Tramites Invima",
                                    "descripcion": "✔️ Registro Sanitario   \n✔️ Permiso Sanitario   \n✔️ Notificacion Sanitaria",
                                    "icono": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/0c6b1b44-d1dc-436a-a930-167a2a558f82/"
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ],
                "querySeccionTestimoniosContents": [
                    {
                        "flatData": {
                            "titulo": "Testimonios",
                            "descripcion": "Siempre nos esforzamos en brindar el mejor servicio a nuestros clientes.",
                            "testimonios": [
                                {
                                    "cliente": "Restaurante Rancho San Martin",
                                    "descripcion": "Agradecemos a la empresa Optimized.bio por su valiosa asesoría Sanitaria y Capacitación para Nuestro personal Manipulador de Alimentos.",
                                    "imagen": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/e1f42a93-b8b0-4074-bfac-96ee01f4b8e0/"
                                        }
                                    ]
                                },
                                {
                                    "cliente": "Asadero El Atajo",
                                    "descripcion": "Excelente servicio, contribuye con nuestro crecimiento y desarrollo profesional. La pedagogía utilizada para las capacitaciones en Manipulación de Alimentos ha sido genial.",
                                    "imagen": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/2e85de0e-2de8-492f-ac95-d5449387d127/"
                                        }
                                    ]
                                },
                                {
                                    "cliente": "Sensory Coffee",
                                    "descripcion": "Servicio integral y muy profesional. Los productos para Limpieza y Desinfección Especializada son muy buenos. Queremos continuar capacitándonos, implementar y mantener todo el Saneamiento Básico en nuestro establecimiento.",
                                    "imagen": [
                                        {
                                            "url": "https://devcloudcms.ddns.net/api/assets/prevent-page/ab39cb71-d6d4-43ba-a073-63f9d2f732d0/"
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ],
                "querySeccionClientesContents": [
                    {
                        "flatData": {
                            "titulo": "Nuestros Clientes",
                            "descripcion": "Estamos en el Sur de Colombia, puede consumir los productos de nuestros clientes con confianza."
                        }
                    }
                ],
                "querySeccionNosotrosContents": [
                    {
                        "flatData": {
                            "titulo": "Nosotros",
                            "descripcion": "Somos una empresa líder y pionera en Control Sanitario y SG - SST con más de 5 años de reconocida trayectoria en el sector alimentario.",
                            "contenido": {
                                "text": "![image](https://devcloudcms.ddns.net/api/assets/prevent-page/6ae69e36-b806-4bc2-a0f6-0c0b5220c45c/image.png 'image')\n\n**¿Cual es nuestro Objetivo?**\n\nGarantizar la inocuidad de los productos y servicios alimentario y hotelero implementando el programa preventivo de plagas en toda la zona sur y eco-turística de Colombia.\n\n**¿Qué hacemos para lograrlo?**\n\nApoyar en la inocuidad del sector alimentario y hotelero como contribución con la salud pública implementando el programa preventivo de plagas en las diferentes fábricas de alimentos, servicios de alimentación, panaderías, restaurantes, cafeterías, expendios de carne, expendios de comida rápida y hoteles para la obtención de productos y servicios con altos estándares de calidad."
                            }
                        }
                    }
                ]
            },
            "extensions": {
                "tracing": {
                    "version": 1,
                    "startTime": "2022-10-31T03:12:58.5243418Z",
                    "endTime": "2022-10-31T03:12:58.5323418Z",
                    "duration": 7991700,
                    "parsing": {
                        "startOffset": 800,
                        "duration": 18600
                    },
                    "validation": {
                        "startOffset": 20299,
                        "duration": 431400
                    },
                    "execution": {
                        "resolvers": []
                    }
                }
            }
        }
    },
]