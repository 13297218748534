import React from "react";
import Tab from "../../components/Tab";

function Clients(params) {
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [listDepartamentEmpresas, setDepartamentEmpresas] = React.useState([]);
    const [activeTab, setActualTab] = React.useState("");
    const [depActual, setDepActual] = React.useState({});

    React.useEffect(() => {
        fetch('https://devcloud.ddns.net/optimizedapi/GetData.asmx/getDepartamentsClients')
            .then(results => results.json())
            .then(departamentos => {
                const filteredDepartamentos = departamentos.filter(departamento => departamento.Empresas.length > 0);
                setDepartamentEmpresas(filteredDepartamentos);
                setDataLoaded(true);
                setDepActual(filteredDepartamentos[0]);
                setActualTab(filteredDepartamentos[0].Code);
            });
    }, []);

    const onClickTabItem = (tab) => {
        var departamento = listDepartamentEmpresas.filter(d => d.Code === tab)[0];
        setDepActual(departamento);
        setActualTab(tab);
    };

    return (
        <section className="section">
            <div className="container">
                <div className="section-inner">
                    <div className="text-center">
                        <div className="container-sm">
                            <h2 className="mt-0 mb-0">
                                {params.data.flatData.titulo}
                            </h2>
                            <p className="section-paragraph">
                                {params.data.flatData.descripcion}
                            </p>
                        </div>
                    </div>
                    <div>
                        {
                            (dataLoaded) ?
                                <div>
                                    <div className="tabs">
                                        <ol className="tab-list">
                                            {
                                                listDepartamentEmpresas.map((dep, index) => {
                                                    return (
                                                        <Tab
                                                            activeTab={activeTab}
                                                            key={dep.Nombre}
                                                            label={dep.Code}
                                                            onClick={onClickTabItem}
                                                        />
                                                    );
                                                })
                                            }
                                        </ol>
                                        <div className="tab-content">
                                            <div className="row">
                                                {
                                                    (activeTab !== "") ?
                                                        (depActual.Empresas.length > 0) ?
                                                            depActual.Empresas.map((emp, index) => {
                                                                return (
                                                                    <div key={index} className="col-md-4" style={{ paddingBottom: '1rem' }}>
                                                                        <a href={emp.Link} target='_blank' rel='noreferrer'>
                                                                            <div className="card animateCard">
                                                                                <div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <img className="userImage" src={emp.LogoUrl} alt={emp.Nombre} />
                                                                                    <h5 className="card-title" style={{ paddingLeft: '5px', margin: 0, textAlign: 'left' }}>
                                                                                        {emp.Nombre}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div style={{ textAlign: 'center' }}>
                                                                Muy pronto iniciaremos operaciones en el departamento de {depActual.Nombre}.
                                                            </div>
                                                        :
                                                        <div style={{ textAlign: 'center' }}>
                                                            Seleccione un departamento...
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                        </div>
                                        <p>Cargando Clientes...</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Clients;