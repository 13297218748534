import React from "react";

function Footer() {
    const [visitas, setVisitas] = React.useState(null);

    React.useEffect(() => {
        fetch('https://devcloud.ddns.net/optimizedapi/GetData.asmx/getVisitas')
            .then(results => results.json())
            .then(data => {
                setVisitas(data);
                fetch('https://devcloud.ddns.net/optimizedapi/GetData.asmx/setVisita')
                    .then(results => results.json())
                    .then(data => { });
            });
    }, []);

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="site-footer-inner has-top-divider">
                    <div className="brand footer-brand">
                        <a href="/">
                            <div style={{ display: "flex", lineHeight: 1, fontSize: 32 }}>
                                <img src={"/images/logo35.png"} style={{ height: 35 }} alt="Logo" />
                                <span style={{ paddingLeft: 4, fontWeight: 'bold' }}>OPTIMIZED</span>
                                <span style={{ paddingLeft: 0, fontWeight: 'normal' }}>.BIO</span>
                            </div>
                        </a>
                    </div>
                    <ul className="footer-links list-reset">
                        <li>
                            <a href="tel:3132079323">📞3132079323</a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.facebook.com/gruporednegocios">
                                🔵Facebook
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.youtube.com/@optimizedprevent-jd8xl">
                                🔴YouTube
                            </a>
                        </li>
                    </ul>
                    <ul className="footer-social-links list-reset" style={{ textAlign: 'center' }}>
                        <li style={{ fontSize: '28px', paddingTop: '5px' }}>
                            {"Visitas:"}
                        </li>
                        <li>
                            <span>
                                {"Hoy"}
                                <br />
                                {
                                    (visitas) ?
                                        visitas.hoy
                                        : "..."
                                }
                            </span>
                        </li>
                        <li>
                            <span>
                                {"Este Mes"}
                                <br />
                                {
                                    (visitas) ?
                                        visitas.mes
                                        : "..."
                                }
                            </span>
                        </li>
                        <li>
                            <span>
                                {"Total"}
                                <br />
                                {
                                    (visitas) ?
                                        visitas.total
                                        : "..."
                                }
                            </span>
                        </li>
                    </ul>
                    {/* <ul className="footer-social-links list-reset">
                        <li>
                            <a href="https://www.facebook.com/gruporednegocios">
                                <span className="screen-reader-text">Facebook</span>
                                <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" fill="#FFF" />
                                </svg>
                            </a>
                        </li>
                    </ul> */}
                    <div className="footer-copyright">© Optimized.bio, todos los derechos reservados</div>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                Desarrollado por: <a href="https://devcloud.com.co" target="blank">DevCloud - Desarrollo de Software</a>
            </div>
        </footer>
    );
}

export default Footer;