import { React, Component } from "react";
import Section from "../components/section";
import '../CmsApi.js';

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    componentDidMount() {
        var pageId = window.location.pathname.split('/').reverse()[0];
        global.cmsApi.getPageCms(pageId, (data) => {
            try {
                var list = data.queryPaginaContents[0].flatData.secciones;
                this.setState({
                    data: list
                })
            } catch (error) {
            }
        });
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <>
                {
                    this.state.data
                        ? <div className="py-2 min-h-screen">
                            <div key='uniqueKey' className="relative flex flex-col min-w-0 break-words w-full border-0">
                                {
                                    this.state.data.map((item, index) => (
                                        <Section key={index} data={item}></Section>
                                    ))
                                }
                            </div>
                        </div>
                        :
                        <div className="min-h-screen relative flex flex-col min-w-0 w-full border-0 p-12 text-center align-middle">
                            <div className="py-40 md:py-56">
                                <h1 className="text-gray-700 font-semibold text-xl">
                                    Pagina no encontrada
                                </h1>
                                <p className="text-gray-600 text-sm max-w-[300px] mx-auto">
                                    La pagina que estas buscando no existe.
                                </p>
                            </div>
                        </div>
                }
            </>
        );
    }
}

export default Page;